<template>
  <v-container>
    <v-row>
      <v-col cols="12">

        <p class="title">Upload Activity</p>

        <div class="example-full">

          <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
            <h3>Drop files (or folder) to upload</h3>
          </div>
          <div class="upload" v-show="!isOption">

            <div class="table-responsive">
              <v-simple-table>
                <thead>
                <tr>
                  <th>#</th>
                  <th>Thumb</th>
                  <th>Name</th>
                  <th>Dimensions</th>
                  <th>Size</th>
                  <th>Speed</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="!files.length">
                  <td colspan="7" style="height: 300px;">

                      <p class="text-h5 text-center" >Drop files (or folder) to upload</p>


                      <p class="subtitle-1 text-center">or</p>

                    <v-row>
                      <v-spacer></v-spacer>
                      <v-spacer></v-spacer>
                        <v-btn large @click="onAddFiles">
                          <v-icon left>mdi-file-plus-outline</v-icon>
                          Add files
                        </v-btn>

                      <v-spacer></v-spacer>
                      <v-btn large @click="onAddFolder">
                        <v-icon left>mdi-folder-plus-outline</v-icon>
                        Add folder
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-spacer></v-spacer>

                    </v-row>
                  </td>
                </tr>
                <tr v-for="(file, index) in files" :key="file.id">
                  <td>{{index}}</td>
                  <td>
                    <img class="td-image-thumb" v-if="file.thumb" :src="file.thumb" />
                    <span v-else>No Image</span>
                  </td>
                  <td>
                    <div class="filename">
                      {{file.name}} <v-icon small color="success" v-if="file.progress === 100">mdi-check</v-icon>
                    </div>
                    <p class="caption error--text mb-0" v-if="file.error">{{file.error}}</p>
                    <v-progress-linear :value="file.progress" v-else-if="file.progress && file.progress !== '0.00' && file.progress !== 0" :color="file.progress === 100 ? 'success' : 'warning'" ></v-progress-linear>
                  </td>
                  <td>{{file.width || 0}} x {{file.height || 0}}</td>
                  <td>{{formatSize(file.size)}}</td>
                  <td>{{formatSize(file.speed)}}</td>
                  <td class="text-right">
                    <v-icon @click.prevent="$refs.upload.remove(file)" :disabled="file.active || file.success">mdi-delete</v-icon>
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
            </div>



            <v-divider class="mb-3"></v-divider>






            <v-container>

              <v-row v-if="firestore || firestore === 0">
                <v-col>
                  <v-progress-linear
                      :value="firestore"
                      height="25"
                      color="amber darken-2"
                      dark
                  >
                    <strong>Firestore: {{firestoreProgress}} of {{firestoreTotal}}</strong>
                  </v-progress-linear>
                </v-col>
              </v-row>

              <v-row v-if="influx || influx === 0">
                <v-col>
                  <v-progress-linear
                      :value="influx"
                      height="25"
                      color="blue"
                      dark
                  >
                    <strong>InfluxDB: {{influxProgress}} of {{influxTotal}}</strong>
                  </v-progress-linear>
                </v-col>
              </v-row>

              <v-row v-if="errors && errors.length && errors.length > 0">
                <v-col>
                  <v-expansion-panels dark>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold" color="error">
                        {{ errors.length }} Errors
                      </v-expansion-panel-header>
                      <v-expansion-panel-content color="error">
                        <p v-for="(error, i) in errors" :key="i"><code >{{error}}</code></p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                <v-select
                    :disabled="!(!$refs.upload || !$refs.upload.active) && ($refs.upload && !$refs.upload.uploaded)"
                    hide-details
                    :items="users"
                    v-model="user"
                    label="User"
                    solo
                ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn block disabled v-if="$refs.upload && Array.isArray($refs.upload.files) && $refs.upload.files.length === 0">
                    Please select a user
                  </v-btn>
              <v-btn block color="success" v-else-if="(!$refs.upload || !$refs.upload.active) && ($refs.upload && !$refs.upload.uploaded)" @click="startUpload"
              :disabled="!user">
                <v-icon left>mdi-upload</v-icon>
                Start Upload
              </v-btn>
              <v-btn block color="error" v-else-if="!$refs.upload || !$refs.upload.uploaded" @click.prevent="$refs.upload.active = false">
                <v-icon left>mdi-cancel</v-icon>
                Stop Upload
              </v-btn>
                  <v-btn block v-else color="info">
                    <v-icon left>mdi-checkmark</v-icon>
                    Upload Successful
                  </v-btn>
              <v-spacer></v-spacer>
                </v-col>
                <v-col>
                  <v-spacer></v-spacer>
              <v-btn block type="button"  @click.prevent="isOption = !isOption">
                <v-icon left>mdi-cog</v-icon>
                Options
              </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <v-divider class="mt-3"></v-divider>


            <div class="footer-status float-right">
              Drop: {{$refs.upload ? $refs.upload.drop : false}},
              Active: {{$refs.upload ? $refs.upload.active : false}},
              Uploaded: {{$refs.upload ? $refs.upload.uploaded : true}},
              Drop active: {{$refs.upload ? $refs.upload.dropActive : false}}
            </div>

            <file-upload
                class="btn btn-primary dropdown-toggle"
                :custom-action="firestoreUpload"
                :extensions="extensions"
                :accept="accept"
                :multiple="multiple"
                :directory="directory"
                :create-directory="createDirectory"
                :size="size || 0"
                :thread="thread < 1 ? 1 : (thread > 5 ? 5 : thread)"
                :headers="headers"
                :data="data"
                :drop="drop"
                :drop-directory="dropDirectory"
                :add-index="addIndex"
                v-model="files"
                @input-filter="inputFilter"
                @input-file="inputFile"
                ref="upload">
            </file-upload>

          </div>





          <div class="option" v-show="isOption">
            <div class="form-group">
              <label for="accept">Accept:</label>
              <input type="text" id="accept" class="form-control" v-model="accept">
              <small class="form-text text-muted">Allow upload mime type</small>
            </div>
            <div class="form-group">
              <label for="extensions">Extensions:</label>
              <input type="text" id="extensions" class="form-control" v-model="extensions">
              <small class="form-text text-muted">Allow upload file extension</small>
            </div>
            <div class="form-group">
              <label>PUT Upload:</label>
              <div class="form-check">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" name="put-action" id="put-action" value="" v-model="putAction"> Off
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" name="put-action" id="put-action" value="/upload/put" v-model="putAction"> On
                </label>
              </div>
              <small class="form-text text-muted">After the shutdown, use the POST method to upload</small>
            </div>
            <div class="form-group">
              <label for="thread">Thread:</label>
              <input type="number" max="5" min="1" id="thread" class="form-control" v-model.number="thread">
              <small class="form-text text-muted">Also upload the number of files at the same time (number of threads)</small>
            </div>
            <div class="form-group">
              <label for="size">Max size:</label>
              <input type="number" min="0" id="size" class="form-control" v-model.number="size">
            </div>
            <div class="form-group">
              <label for="minSize">Min size:</label>
              <input type="number" min="0" id="minSize" class="form-control" v-model.number="minSize">
            </div>


            <div class="form-group">
              <div class="form-check">
                <label class="form-check-label">
                  <input type="checkbox" id="add-index" class="form-check-input" v-model="addIndex"> Start position to add
                </label>
              </div>
              <small class="form-text text-muted">Add a file list to start the location to add</small>
            </div>

            <div class="form-group">
              <div class="form-check">
                <label class="form-check-label">
                  <input type="checkbox" id="drop" class="form-check-input" v-model="drop"> Drop
                </label>
              </div>
              <small class="form-text text-muted">Drag and drop upload</small>
            </div>
            <div class="form-group">
              <div class="form-check">
                <label class="form-check-label">
                  <input type="checkbox" id="drop-directory" class="form-check-input" v-model="dropDirectory"> Drop directory
                </label>
              </div>
              <small class="form-text text-muted">Not checked, filter the dragged folder</small>
            </div>
            <div class="form-group">
              <div class="form-check">
                <label class="form-check-label">
                  <input type="checkbox" id="create-directory" class="form-check-input" v-model="createDirectory"> Create Directory
                </label>
              </div>
              <small class="form-text text-muted">The directory file will send an upload request. The mime type is <code>text/directory</code></small>
            </div>
            <div class="form-group">
              <div class="form-check">
                <label class="form-check-label">
                  <input type="checkbox" id="upload-auto" class="form-check-input" v-model="uploadAuto"> Auto start
                </label>
              </div>
              <small class="form-text text-muted">Automatically activate upload</small>
            </div>
            <div class="form-group">
              <button type="button" class="btn btn-primary btn-lg btn-block" @click.prevent="isOption = !isOption">Confirm</button>
            </div>
          </div>

        </div>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import Cropper from 'cropperjs'
import FileUpload from 'vue-upload-component'
import {db, functions, storage} from '../plugins/firebase'

  export default {
    name: 'HomeActivity',

    components: {
      FileUpload,
    },

    data() {
      return {
        errors: [],
        firestoreTotal: 0,
        firestoreProgress: 0,
        influxTotal: 0,
        influxProgress: 0,
        docRef: '',
        users: ['Peter', 'Paul', 'Simon'],
        user: '',
        files: [],
        accept: 'image/png,text/csv,text/plain',
        extensions: 'txt,csv,png',
        // extensions: ['gif', 'jpg', 'jpeg','png', 'webp'],
        // extensions: /\.(gif|jpe?g|png|webp)$/i,
        minSize: 0,
        size: 1024 * 1024 * 50,
        multiple: true,
        directory: false,
        drop: true,
        dropDirectory: true,
        createDirectory: false,
        addIndex: false,
        thread: 3,
        name: 'file',
        postAction: '/upload/post',
        putAction: '/upload/put',
        headers: {
          'X-Csrf-Token': 'xxxx',
        },
        data: {
          '_csrf_token': 'xxxxxx',
        },
        uploadAuto: false,
        isOption: false,
        addData: {
          show: false,
          name: '',
          type: '',
          content: '',
        },
        editFile: {
          show: false,
          name: '',
        }
      }
    },
    watch: {
      'editFile.show'(newValue, oldValue) {
        // 关闭了 自动删除 error
        if (!newValue && oldValue) {
          this.$refs.upload.update(this.editFile.id, { error: this.editFile.error || '' })
        }
        if (newValue) {
          this.$nextTick( () => {
            if (!this.$refs.editImage) {
              return
            }
            let cropper = new Cropper(this.$refs.editImage, {
              autoCrop: false,
            })
            this.editFile = {
              ...this.editFile,
              cropper
            }
          })
        }
      },
      'addData.show'(show) {
        if (show) {
          this.addData.name = ''
          this.addData.type = ''
          this.addData.content = ''
        }
      },
    },
    computed: {
      influx() {
        return Math.ceil(this.influxProgress/this.influxTotal*100)
      },
      firestore() {
        return Math.ceil(this.firestoreProgress/this.firestoreTotal*100)
      },
    },
    methods: {

      startUpload() {

        let self = this

        if (self.user) {

        db.collection("UploadActivity").add({
          timestamp: Date.now(),
          user: self.user
        })
            .then((docRef) => {
              console.log("Document written with ID: ", docRef.id);
              self.docRef = docRef.id
              this.$refs.upload.active = true
            })
            .catch((error) => {
              //console.error("Error adding document: ", error);
              this.errors.push("Error adding document: "+ error)
            });

        }
      },

      async uploadTask(file, refs, doc) {
        return new Promise(function(resolve, reject) {


          let pathName = file.name;
          let mimeType = file.type;

          let metadata = {
            contentType: mimeType,
          };

          let storageRef = storage.ref()
          let fileRef = storageRef.child(doc).child(pathName);


          let task


          try {

            task = fileRef.put(file.file, metadata)

            task.on('state_changed', function(snapshot) {
              // Observe state change events such as progress, pause, and resume
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              let progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
              refs.upload.update(file, {progress: progress})

              // console.log('Upload is ' + progress + '% done');
              switch (snapshot.state) {
                case 'paused': // or 'paused'
                  //console.log('Upload is paused');
                  //refs.upload.update(file, {active: false})
                  break;
                case 'running': // or 'running'
                  //refs.upload.update(file, {active: true})
                  break;
              }
            }, function(error) {
              this.errors.push("Error uploading document: "+ error)
              // Handle unsuccessful uploads
              refs.upload.update(file, {error: 'abort'})
              reject()
            }, function() {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              task.snapshot.ref.getDownloadURL().then(function(downloadURL) {

                refs.upload.update(file, {progress: 100})

                resolve(downloadURL)

              });

            });

          } catch (e) {
            console.log(e)
            refs.upload.update(file, {error: 'abort'})
            reject()
          }

        })
      },

      async firestoreUpload(file) {

        console.log(file)

        if (this.$refs.upload && !this.$refs.upload.uploaded) {
          let downloadURL = await this.uploadTask(file, this.$refs, this.docRef)

          const queryString = downloadURL.split("?");
          const urlParams = new URLSearchParams(queryString[1]);
          const token = urlParams.get('token')
          let pathName = file.name;
          let mimeType = file.type;

          let fileMeta = {}
          if (downloadURL) {fileMeta._URL = downloadURL} else {fileMeta._URL = null}
          if (token) {fileMeta._TOKEN = token} else {fileMeta._TOKEN = null}
          if (pathName) {fileMeta._FILENAME = pathName} else {fileMeta._FILENAME = null}
          if (mimeType) {fileMeta._MIMETYPE = mimeType} else {fileMeta._MIMETYPE = null}
          fileMeta._TIMESTAMP = Date.now()
          fileMeta._USER = this.user
          fileMeta._FILETYPE = this.fileType(file.name)
          fileMeta._ACTIVITY = this.docRef

          let self = this

          db.collection("UploadActivity").doc(self.docRef).collection("files").add(fileMeta)
              .then((docRef) => {
                console.log("File document written with ID: ", docRef.id);
              })
              .catch((error) => {
                this.errors.push("Error adding document: "+ error)
              });

          if (mimeType === 'text/csv') {
            let reader = new FileReader();
            reader.readAsText(file.file);
            reader.onload = function(event){
              let csv = event.target.result;
              const data = self.csvToArray(csv);

              const fileType = self.fileType(file.name)
              data.forEach(element => {
                self.csvToFirestore(element, file.name, fileType)
              })


            };
            reader.onerror = function(){
              this.errors.push("Cannot read CSV file: "+ file.name)
            }
          }

          console.log(downloadURL)
        }

      },

      fileType (fileName) {
        let fileType = 'unknown'
        if (fileName.startsWith('HK_FPGA_')) {
          fileType = 'HK_FPGA'
        }
        return fileType
      },

      csvToFirestore (json, fileName, fileType) {

        this.firestoreTotal++

        let self = this

        let m = this.$moment.parseZone(json.TIME)

        json._UTC = m.utcOffset()
        json._TIMESTAMP = Date.parse(json.TIME)
        json._FILENAME = fileName
        json._FILETYPE = fileType
        json._ACTIVITY = self.docRef

        try {
          db.collection("UploadActivity").doc(self.docRef).collection("logs").add(json)
              .then(() => {
                //console.log("Log document written with ID: ", docRef.id);
                this.firestoreProgress++

                if (fileType === 'HK_FPGA') {
                  this.toInfluxDb(json)
                }

              })
              .catch((error) => {
                this.errors.push("Error adding document: "+ error)
              });
        } catch (e) {
          this.errors.push("Firestore error: "+ e)
        }



      },

      toInfluxDb(data) {
        this.influxTotal++

        functions.httpsCallable('influx')(data)
            .then(() => {
              this.influxProgress++
            })
            .catch((error) => {
              this.errors.push("InfluxDB error: "+ error)
              console.log(error)
            });
      },

      csvToArray(str, delimiter = ",") {
        // slice from start of text to the first \n index
        // use split to create an array from string by delimiter
        const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

        // slice from \n index + 1 to the end of the text
        // use split to create an array of each csv value row
        const rows = str.slice(str.indexOf("\n") + 1).split("\n").filter(String); //filter empty rows

        // Map the rows
        // split values from each row into an array
        // use headers.reduce to create an object
        // object properties derived from headers:values
        // the object passed as an element of the array
        const arr = rows.map(function (row) {
          const values = row.split(delimiter);
          const el = headers.reduce(function (object, header, index) {
            object[header] = values[index];
            return object;
          }, {});
          return el;

        });

        // return the array
        return arr;
      },

      formatSize(size) {
        if (size > 1024 * 1024 * 1024 * 1024) {
          return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
        } else if (size > 1024 * 1024 * 1024) {
          return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
        } else if (size > 1024 * 1024) {
          return (size / 1024 / 1024).toFixed(2) + ' MB'
        } else if (size > 1024) {
          return (size / 1024).toFixed(2) + ' KB'
        }
        return size.toString() + ' B'
      },

      inputFilter(newFile, oldFile, prevent) {
        if (newFile && !oldFile) {
          // Before adding a file
          // 添加文件前
          // Filter system files or hide files
          // 过滤系统文件 和隐藏文件
          if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
            return prevent()
          }
          // Filter php html js file
          // 过滤 php html js 文件
          if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
            return prevent()
          }
          // Automatic compression
          // 自动压缩

        }
        if (newFile && newFile.error === "" && newFile.file && (!oldFile || newFile.file !== oldFile.file)) {
          // Create a blob field
          // 创建 blob 字段
          newFile.blob = ''
          let URL = (window.URL || window.webkitURL)
          if (URL) {
            newFile.blob = URL.createObjectURL(newFile.file)
          }
          // Thumbnails
          // 缩略图
          newFile.thumb = ''
          if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
            newFile.thumb = newFile.blob
          }
        }
        // image size
        // image 尺寸
        if (newFile && newFile.error === '' && newFile.type.substr(0, 6) === "image/" && newFile.blob && (!oldFile || newFile.blob !== oldFile.blob)) {
          newFile.error = 'image parsing'
          let img = new Image();
          img.onload = () => {
            this.$refs.upload.update(newFile, {error: '', height: img.height, width: img.width})
          }
          img.onerror = (e) => {
            this.errors.push("Image size filter error: "+ e)
            this.$refs.upload.update(newFile, { error: 'parsing image size'})
          }
          img.src = newFile.blob
        }
      },
      // add, update, remove File Event
      inputFile(newFile, oldFile) {
        if (newFile && oldFile) {
          // update
          if (newFile.active && !oldFile.active) {
            // beforeSend
            // min size
            if (newFile.size >= 0 && this.minSize > 0 && newFile.size < this.minSize) {
              this.$refs.upload.update(newFile, { error: 'size' })
            }
          }
          if (newFile.progress !== oldFile.progress) {
            // progress
          }
          if (newFile.error && !oldFile.error) {
            // error
          }
          if (newFile.success && !oldFile.success) {
            // success
          }
        }
        if (!newFile && oldFile) {
          // remove
          if (oldFile.success && oldFile.response.id) {
            // $.ajax({
            //   type: 'DELETE',
            //   url: '/upload/delete?id=' + oldFile.response.id,
            // })
          }
        }
        // Automatically activate upload
        if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
          if (this.uploadAuto && !this.$refs.upload.active) {
            this.$refs.upload.active = true
          }
        }
      },
      alert(message) {
        alert(message)
      },
      onEditFileShow(file) {
        this.editFile = { ...file, show: true }
        this.$refs.upload.update(file, { error: 'edit' })
      },
      onEditorFile() {
        if (!this.$refs.upload.features.html5) {
          this.alert('Your browser does not support')
          this.editFile.show = false
          return
        }
        let data = {
          name: this.editFile.name,
          error: '',
        }
        if (this.editFile.cropper) {
          let binStr = atob(this.editFile.cropper.getCroppedCanvas().toDataURL(this.editFile.type).split(',')[1])
          let arr = new Uint8Array(binStr.length)
          for (let i = 0; i < binStr.length; i++) {
            arr[i] = binStr.charCodeAt(i)
          }
          data.file = new File([arr], data.name, { type: this.editFile.type })
          data.size = data.file.size
        }
        this.$refs.upload.update(this.editFile.id, data)
        this.editFile.error = ''
        this.editFile.show = false
      },
      // add files
      onAddFiles() {
        let label = document.createElement('label')
        label.setAttribute('for', this.name)
        label.style = "background: rgba(255, 255, 255, 0);overflow: hidden;position: fixed;width: 1px;height: 1px;z-index: -1;opacity: 0;"
        document.querySelector("body").appendChild(label)
        label.click()
        console.log(label)
      },
      // add folder
      onAddFolder() {
        if (!this.$refs.upload.features.directory) {
          this.alert('Your browser does not support')
          return
        }
        let input = document.createElement('input')
        input.style = "background: rgba(255, 255, 255, 0);overflow: hidden;position: fixed;width: 1px;height: 1px;z-index: -1;opacity: 0;"
        input.type = 'file'
        input.setAttribute('allowdirs', true)
        input.setAttribute('directory', true)
        input.setAttribute('webkitdirectory', true)
        input.multiple = true
        document.querySelector("body").appendChild(input)
        input.click()
        input.onchange = (e) => {
          this.errors.push("Add folder error: "+ e)
          this.$refs.upload.addInputFile(input).then(function() {
            document.querySelector("body").removeChild(input)
          })
        }
      },
      onAddData() {
        this.addData.show = false
        if (!this.$refs.upload.features.html5) {
          this.alert('Your browser does not support')
          return
        }
        let file = new window.File([this.addData.content], this.addData.name, {
          type: this.addData.type,
        })
        this.$refs.upload.add(file)
      }
    }
  }
</script>

<style>
.example-full .btn-group .dropdown-menu {
  display: block;
  visibility: hidden;
  transition: all .2s
}
.example-full .btn-group:hover > .dropdown-menu {
  visibility: visible;
}
.example-full label.dropdown-item {
  margin-bottom: 0;
}
.example-full .btn-group .dropdown-toggle {
  margin-right: .6rem
}
.td-image-thumb {
  max-width: 4em;
  max-height: 4em;
}
.example-full .filename {
  margin-bottom: .3rem
}
.example-full .btn-is-option {
  margin-top: 0.25rem;
}
.example-full .example-foorer {
  border-top: 1px solid #e9ecef;
}
.example-full .edit-image img {
  max-width: 100%;
}
.example-full .edit-image-tool {
  margin-top: .6rem;
}
.example-full .edit-image-tool .btn-group{
  margin-right: .6rem;
}
.example-full .footer-status {
  padding-top: .4rem;
}
.example-full .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: .6;
  text-align: center;
  background: #000;
}
.example-full .drop-active h3 {
  margin: -.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
</style>
