<template>
  <upload-activity />
</template>

<script>
  import UploadActivity from '../components/UploadActivity'

  export default {
    name: 'Upload',

    components: {
      UploadActivity,
    },
  }
</script>
